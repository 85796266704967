import React, {Fragment, useEffect, useState} from 'react';
import {Col, Row, Button, ModalHeader, Modal} from 'reactstrap';
import {useDispatch, useSelector} from 'react-redux';
import {NavLink, useNavigate, useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {formatCredits} from '../../utils/credits';
import {
    deleteOrganization,
    fetchOrganization,
    fetchOrganizationUsers,
    updateOrganization,
} from '../../slices/organizations.slice';
import UserCard from '../../components/UserCard';
import TierBadge from '../../components/TierBadge';
import Avatar from '../../components/Avatar';
import CinemaProperties from './forms/CinemaProperties';
import BacServer from './forms/BacServer';
import MediablockProperties from './forms/MediablockProperties';
import DiskUsage from '../../components/DiskUsage';
import {getCurrencyInfo} from '../../utils/currencies';
import EditModal from '../../components/EditModal';
import TmsSettings from './forms/TmsSettings';
import DeleteButton from '../../components/DeleteButton';
import './OrganizationDetails.scss'

const OrganizationDetails = () => {
    const { organizationId } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const token = useSelector(state => state.auth.token);
    const user = useSelector(state => state.auth.user);
    const organization = useSelector((state) => state.organizations.organization);
    const status = useSelector((state) => state.organizations.organizationStatus);
    const organizationUsers = useSelector((state) => state.organizations.organizationUsers);
    const orgUserStatus = useSelector((state) => state.organizations.organizationUsersStatus);
    const [editModal, setEditModal] = useState({
        open: false,
        form: '',
        title: '',
    });
    const [logoModal, setLogoModal] = useState({open: false, value: '' });

    const toggleEditModal = () => {
        setEditModal(prev => ({ ...prev, open: !prev.open }));
    }

    const bacView = ['bac-admin', 'bac-user', 'root'].includes(user?.role)

    const handleUpdate = field => value => {
        const organizationBody = { ...organization, [field]: value };
        const orgId = organization._id;
        const promise = dispatch(updateOrganization({ token, organizationBody, orgId })).unwrap();
        toast.promise(promise, {
            pending: 'Updating...',
            success: 'Changes saved',
            error: 'Error updating changes',
        });
        promise.then(() => {
            if (field === 'logo') {
                setLogoModal({ open: !logoModal.open })
            } else {
                toggleEditModal();
            }
            dispatch(fetchOrganization({ token, orgId }));
        });
    };

    const handleDelete = async () => {
        const promise = dispatch(deleteOrganization(organizationId)).unwrap();
        toast.promise(promise, {
            pending: 'Deleting organization',
            success: 'Organization deleted successfully',
            error: 'Error deleting organization',
        });
        await promise;
        navigate('/organizations');
    };

    useEffect(() => {
        if (!user) return;
        const orgId = organizationId === 'own' ? user.parentOrganization?._id : organizationId;
        dispatch(fetchOrganization({ token, orgId }));
        dispatch(fetchOrganizationUsers({ token, orgId }));
    }, [token, dispatch, organizationId, user, bacView]);

    return status === 'success' && (
        <div className="organization-view p-3 ps-4 ms-1 vh-100">
            {['root', 'bac-user', 'bac-admin'].includes(user?.role) && (
                <Row>
                    <Col>
                        <NavLink to="/organizations" className="text-decoration-none" >
                            <span className="fas fa-chevron-left me-2" /> Organizations
                        </NavLink>
                    </Col>
                </Row>
            )}

            <Row className="header border-start border-primary bg-broken" xs="4">
                <Col className="pic-container" xs="3" sm="auto">
                    <Avatar
                        imageUrl={organization.logo?.imageUrl}
                        name={organization.name}
                        size={100}
                        onEdit={() => setLogoModal({ open: !logoModal.open })}
                    />
                </Col>
                <Col className="header-content flex-grow-1">
                    {organization.lastSynced && (
                        <div className="mb-2 float-end">
                            <span className="fas fa-refresh me-2 text-primary"/>
                            Last synced: {new Date(organization.lastSynced).toLocaleString()}
                        </div>
                    )}

                    <h1 className="name text-capitalize text-primary d-flex align-items-center">
                        {organization.name}
                    </h1>
                    <div className="d-flex gap-3">
                    {!user.role.startsWith('site-') && organization.currency && (
                            <span className="border border-primary px-2 text-primary d-flex align-items-center">
                                <span className="fas fa-sack-dollar me-2"/>
                                {organization.currency} - {getCurrencyInfo(organization.currency).label}
                            </span>
                        )}

                        <TierBadge tier={organization.tier}/>

                        {bacView && (
                            <NavLink to={`/organizations/${organizationId}/edit`}>
                                <Button className="h-100" color="primary">
                                    <span className="fas fa-pencil me-2"/>
                                    Edit
                                </Button>
                            </NavLink>
                        )}
                    </div>


                </Col>
            </Row>

            <div className="details">
                <NavLink to={`/organizations/${bacView ? organization._id : 'own'}/transactions`}
                         className="text-white text-decoration-none">
                    <div
                        className="d-flex position-relative border-start border-primary bg-broken details-card align-items-center mb-3">
                        <span
                            className="fas fa-coins bg-broken position-absolute align-items-center d-flex justify-content-center text-primary"/>
                        <div>
                            <p className="mb-0 text-uppercase text-primary">
                                Credits
                                <span className="fas fa-list ms-2 edit-icon"/>
                            </p>
                            <p className="mb-0">{formatCredits(organization.creditAmount)} credits</p>
                        </div>
                    </div>
                </NavLink>

                <div
                    className="d-flex position-relative border-start border-primary bg-broken details-card align-items-center mb-3"
                    onClick={() => setEditModal({
                        open: true,
                        form: <MediablockProperties
                            initialSettings={organization.mediablock}
                            onSave={handleUpdate('mediablock')}
                            toggle={toggleEditModal}
                            bacView={bacView}
                        />,
                        title: 'Mediablock Properties',
                    })}
                >
                    <span
                        className="fas fa-fw fa-database bg-broken position-absolute align-items-center d-flex justify-content-center text-primary"/>
                    <div>
                        <p className="mb-0 text-uppercase text-primary">
                            Mediablock status
                            <span className="fas fa-eye ms-2 edit-icon"/>
                        </p>
                        <div className="d-flex align-items-center gap-1 mt-1">
                            <div className="me-3 text-center">
                                <span
                                    className={`fas me-2 ${organization.mediablock?.status === 'Connected' ? 'text-success fa-toggle-on' : 'text-danger fa-toggle-off'}`}/>
                                <span className="text-uppercase">
                                    {organization.mediablock?.status}
                                </span>
                            </div>

                            {organization.mediablock?.status === 'Connected' && (
                                <div className="me-3 text-center">
                                    <span className="fas fa-hard-drive me-2 text-primary"/>
                                    <DiskUsage
                                        capacity={organization.mediablock?.capacity}
                                        usedDiskSpace={organization.mediablock?.usedDiskSpace}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                {bacView && (
                    <div
                        className="d-flex position-relative border-start border-primary bg-broken details-card align-items-center mb-3"
                        onClick={() => setEditModal({
                            open: true,
                            form: <BacServer initialSettings={organization.bacServer}
                                             onSave={handleUpdate('bacServer')}/>,
                            title: 'BAC Server Properties',
                        })}
                    >
                    <span
                        className="fas fa-fw fa-server bg-broken position-absolute align-items-center d-flex justify-content-center text-primary"/>
                        <div>
                            <p className="mb-0 text-uppercase text-primary">
                                BAC Server status
                                <span className="fas fa-eye ms-2 edit-icon"/>
                            </p>
                            <div className="d-flex align-items-center gap-1 mt-1">
                                <div className="me-5 text-center">
                                    <span>ALFRED:</span>
                                    <span className={`fas mx-2 ${organization.bacServer?.gofilexDaemonsAlfred === 'online' ? 'text-success fa-toggle-on' : 'text-danger fa-toggle-off' }`} />
                                    <span className="text-uppercase">
                                    {organization.bacServer?.gofilexDaemonsAlfred}
                                </span>
                                </div>
                                <div className="me-3 text-center">
                                    <span>FRANK:</span>
                                    <span
                                        className={`fas mx-2 ${organization.bacServer?.gofilexDaemonsFrank === 'online' ? 'text-success fa-toggle-on' : 'text-danger fa-toggle-off'}`}/>
                                    <span className="text-uppercase">
                                    {organization.bacServer?.gofilexDaemonsFrank}
                                </span>
                                </div>


                            </div>
                        </div>
                    </div>
                )}

                {bacView && (
                    <NavLink
                        to={`/organizations/${organizationId}/content`}
                        className="d-flex position-relative border-start border-primary bg-broken details-card align-items-center mb-3 text-decoration-none text-white"
                    >
                    <span
                        className="fas fa-fw fa-file-video bg-broken position-absolute align-items-center d-flex justify-content-center text-primary"/>
                        <div>
                            <p className="mb-0 text-uppercase text-primary">
                                Content Manager
                                <span className="fas fa-eye ms-2 edit-icon"/>
                            </p>
                            View available content on Mediablock and BAC server
                        </div>
                    </NavLink>
                )}

                <div
                    className="d-flex position-relative border-start border-primary bg-broken details-card align-items-center mb-3"
                    onClick={() => bacView && setEditModal({
                        open: true,
                        form: <CinemaProperties initialSettings={organization.cinemaSettings} onSave={handleUpdate('cinemaSettings')}/>,
                        title: 'Cinema Properties',
                    })}
                >
                    <span
                        className="fas fa-fw fa-video bg-broken position-absolute align-items-center d-flex justify-content-center text-primary"/>
                    <div>
                        <p className="mb-0 text-uppercase text-primary">
                            Cinema Properties
                            {bacView && <span className="fas fa-pencil ms-2 edit-icon"/>}
                        </p>
                        <div className="d-flex align-items-center gap-1 mt-1">
                            <div className="me-3 text-center">
                                <span className="fas fa-language me-2 text-primary" title="Spoken Language"/>
                                <span>{organization?.cinemaSettings?.languageSpoken}</span>
                            </div>
                            <div className="me-3 text-center">
                                <span className="fas fa-closed-captioning me-2 text-primary" title="Subtitle Language"/>
                                <span>{organization?.cinemaSettings?.languageSubtitle}</span>
                            </div>
                            <div className="me-3 text-center">
                                <span className="fas fa-volume-up me-2 text-primary" title="Audio Type"/>
                                <span>{organization?.cinemaSettings?.audioType.join(' | ')}</span>
                            </div>
                            <div className="me-3 text-center">
                                <span className="fas fa-expand me-2 text-primary" title="Aspect Ratio"/>
                                <span className="text-capitalize">{organization?.cinemaSettings?.aspectRatio}</span>
                            </div>
                            <div className="me-3 text-center">
                                <span className="fas fa-desktop me-2 text-primary" title="Resolution"/>
                                <span>{organization?.cinemaSettings?.resolution}</span>
                            </div>
                            <div className="me-3 text-center">
                                <span className="fas fa-film me-2 text-primary" title="Frame Rate"/>
                                <span>{`${organization?.cinemaSettings?.framerate} fps`}</span>
                            </div>
                        </div>
                    </div>
                </div>


                {bacView && (
                    <Fragment>
                        <NavLink to={`/organizations/${organizationId}/cues`} className="text-decoration-none text-white">
                            <div
                                className="d-flex position-relative border-start border-primary bg-broken details-card align-items-center mb-3">
                                <span
                                    className="fas fa-fw fa-robot bg-broken position-absolute align-items-center d-flex justify-content-center text-primary"/>
                                <div>
                                    <p className="mb-0 text-uppercase text-primary">
                                        Automation cues
                                        <span className="fas fa-pencil ms-2 edit-icon"/>
                                    </p>
                                    <div className="d-flex align-items-center gap-1 mt-1">
                                        Edit Default Playlist for this organization
                                    </div>
                                </div>
                            </div>
                        </NavLink>

                        <div
                            className="d-flex position-relative border-start border-primary bg-broken details-card align-items-center mb-3"
                            onClick={() => setEditModal({
                                open: true,
                                form: <TmsSettings
                                    initialSettings={organization.tmsSettings}
                                    onSave={handleUpdate('tmsSettings')}
                                />,
                                title: 'TMS Settings',
                            })}
                        >
                            <span
                                className="fas fa-fw fa-gears bg-broken position-absolute align-items-center d-flex justify-content-center text-primary"/>
                            <div>
                                <p className="mb-0 text-uppercase text-primary">
                                    TMS Settings
                                    <span className="fas fa-pencil ms-2 edit-icon"/>
                                </p>
                                <div className="d-flex align-items-center gap-1 mt-1">
                                    Edit TMS Settings

                                    <span
                                        className={`fas me-2 ${organization?.tmsSettings?.status === 'running' ? 'text-success fa-toggle-on' : 'text-danger fa-toggle-off'}`}/>
                                    <span className="text-uppercase">
                                        {organization?.tmsSettings?.status}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                )}

                <div className="organization-users py-4">
                    <h2>
                        Organization users
                    </h2>
                    <NavLink to={`/users/new?parentOrganization=${organization._id}`} className="text-decoration-none">
                        <span className="fas fa-plus me-2"/> Create a user for this organization
                    </NavLink>

                    {orgUserStatus === 'success' && organizationUsers.map(user => (
                        <NavLink to={`/users/${user._id}`} key={user._id} className="text-decoration-none">
                            <UserCard user={user}></UserCard>
                        </NavLink>
                    ))}

                    {organizationUsers.length === 0 && (
                        <div>No users from this organization</div>
                    )}
                </div>

                {bacView && (
                    <div className="float-end mb-3">
                        <DeleteButton
                            entityId={organization._id}
                            entityLabel="organization"
                            title={organization.name}
                            onClick={handleDelete}
                            typeToConfirm
                        />
                    </div>
                )}

                <Modal isOpen={editModal.open} toggle={toggleEditModal} scrollable={true} size="lg">
                    <ModalHeader tag="div" toggle={toggleEditModal} className="">
                        <h1 className="text-uppercase">{editModal.title}</h1>
                    </ModalHeader>

                    {editModal.form}
                </Modal>
            </div>

            <EditModal
                isOpen={logoModal.open}
                toggle={() => setLogoModal({open: !logoModal.open})}
                dataType="image"
                label="Organization logo"
                value={organization.logo?.imageUrl}
                onSave={({_id}) => handleUpdate('logo')(_id)}
                mediaType="org-avatar"
            />
        </div>
    );
};

export default OrganizationDetails;
