import React, {Fragment} from 'react';
import {imgUrl} from '../../utils/api-request';
import {formatBytes} from '../DiskUsage';
import './ContentCard.scss';

export const ContentCard = ({ content, toggleContentModal })=>{
    const packageType = content.movieVersion?.assetDetails?.packageType || ''
    const locationLabel = content.movieVersion?.assetDetails?.location === 'mediablock' ? 'Mediablock' : 'BAC server';

    return  (
        <div className={`info ${packageType} bg-broken mb-2 pointer d-flex align-items-center w-100`}
             onClick={toggleContentModal(content)} key={content._id}>
            { <div  className="content-type">{packageType}</div>}
            {content.movieVersion?.movie?.poster?.imageUrl ? (
                <img
                    className="logo-img"
                    width={75}
                    height={112}
                    alt={content.movieVersion?.movie?.title}
                    src={imgUrl(content.movieVersion?.movie?.poster?.imageUrl)}
                />
            ) : (
                <div
                    style={{ width: '75px', height: '112px' }}
                    className="logo-img d-flex justify-content-center flex-column text-center opacity-50"
                ><span className="fas fa-chain-broken text-primary mb-2" />Poster N/A</div>
            )}
            <div className="d-inline-block ms-3 mb-0 text-truncate mw-100 mb-0 flex-grow-1 py-3">
                <p>
                    <span className="fas fa-file me-2 text-primary"/>
                    {content.contentTitleText}

                    {content.isPinned && <span className="ms-2 fas fa-thumbtack text-danger" />}

                </p>

                {content.type === 'KDM' ? (
                    <Fragment>
                        <span className="text-primary">KDM Valid From:</span>
                        <span
                            className="text-white"> {new Date(content.KDM_notValidBefore).toLocaleDateString()}</span>
                        <span className="mx-2 text-primary">to</span>
                        <span
                            className="text-white">{new Date(content.KDM_notValidAfter).toLocaleDateString()}</span>
                    </Fragment>
                ) : (
                    <Fragment>
                        <span className="text-primary">CPL UUID: </span>
                        <span className="text-white">{content.cpl_uuid}</span>
                    </Fragment>
                )}

                <span className="text-primary ms-3">
                    On {locationLabel} since:
                </span> {new Date(content.onMachine).toLocaleString()}
            </div>

            <div className="me-3">
                {content.hasValidKey && <span className="fas fa-sm fa-key text-success mx-2 float-end border border-primary rounded p-2"/>}
                {content.hasInvalidKey && <span className="fas fa-sm fa-key text-danger mx-2 float-end border border-primary rounded p-2"/>}
                <span
                    className={`badge text-uppercase float-end mb-2
                    ${content.type === 'KDM' ? 'border border-primary text-primary' : 'bg-primary text-black'}`}
                >
                    {content.type}
                </span>
                <br/>
                <span className="badge text-uppercase float-end">
                    <span
                        className="ms-3 fas fa-hard-drive me-2 text-primary"/> {formatBytes(content.fileSize)}
                    {content.type === 'CPL' && ` / ${formatBytes(content.dcpSize || 0)}`}
                </span>
            </div>
        </div>
    )
}