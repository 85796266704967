import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PageHeader from '../../components/PageHeader';
import {nestedSearch} from '../../utils/nestedSearch';
import {fetchAuditLogActions, fetchAuditLogs} from '../../slices/audit-logs.slice';
import Loading from '../../components/Loading';
import DatePicker from 'react-datepicker';
import {
    Badge,
    Button,
    ButtonGroup,
    Label,
    ListGroup,
    ListGroupItem, Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from 'reactstrap';
import {getFiterDatesLabel} from '../../utils/dates';
import SearchBar from '../../components/SearchBar';
import {imgUrl} from '../../utils/api-request';
import {fetchOrganizations} from '../../slices/organizations.slice';
import {fetchUsers} from '../../slices/user.slice';

const AuditLogs = () => {
    const dispatch = useDispatch();
    const { list: auditLogs, actions, status } = useSelector(state => state.auditLogs);
    const { list: users } = useSelector(state => state.users);
    const { list: organizations } = useSelector(state => state.organizations);
    const [filteredValues, setFilteredValues] = useState([]);
    const [searchValue, setSearchInput] = useState('');
    const [searchModalValue, setSearchModalValue] = useState('');
    const [filterModal, setFilterModal] = useState({ isOpen: false, filter: '', filterName: '' });
    const [filterBy, setFilterBy] = useState({
        actions: [],
        users: [],
        organizations: [],
        createdAt: { from: null, to: null },
    });
    const toggleFilterModal = (filter, filterName) => {
        setFilterModal({ isOpen: !filterModal.isOpen, filter, filterName });
    };

    const handleSearch = (event) => {
        event.preventDefault();
        const query = event.target.value.toLowerCase()
        setSearchInput(query);
        const searchFields = [
            'details'
        ]
        let filteredData = nestedSearch([...auditLogs], searchFields, query)
        setFilteredValues(filteredData);
    };

    const toggleFilter = (filterKey, value) => {
        setFilterBy((prevFilterBy) => {
            const isSelected = prevFilterBy[filterKey].includes(value);
            return {
                ...prevFilterBy,
                [filterKey]: isSelected
                    ? prevFilterBy[filterKey].filter((item) => item !== value)
                    : [...prevFilterBy[filterKey], value],
            };
        });
    };

    const filterTextUser = (user) => {
        return [user.firstName, user.lastName, user.username, user.email]
            .some(field => field?.toLowerCase().includes(searchModalValue.toLowerCase()));
    };

    const handleCreatedAt = (period) => (date) => {
        setFilterBy((prevFilterBy) => ({
            ...prevFilterBy,
            createdAt: {
                ...prevFilterBy.createdAt,
                [period]: date,
            },
        }));
    };

    const areFiltersActive = useMemo(() => {
        const { actions, users, organizations, createdAt } = filterBy;

        return (
            actions.length > 0 ||
            users.length > 0 ||
            organizations.length > 0 ||
            createdAt.from !== null ||
            createdAt.to !== null
        );
    }, [filterBy]);

    const clearFilters = () => {
        setFilterBy({
            actions: [],
            users: [],
            organizations: [],
            createdAt: { from: null, to: null },
        })
    };

    useEffect(() => {
        dispatch(fetchAuditLogs(filterBy));
    }, [dispatch, filterBy]);

    useEffect(() => {
        setFilteredValues(auditLogs);
    }, [auditLogs, actions]);

    useEffect(() => {
        dispatch(fetchAuditLogActions());
        dispatch(fetchOrganizations())
        dispatch(fetchUsers())
    }, [dispatch]);

    return (
        <div className="auditlog-view px-4 py-3 d-flex flex-column vh-100 overflow-y-auto">
            <PageHeader
                title="Audit Logs"
                searchBar={{searchValue, handleSearch}}
            />

            <div className="d-flex justify-content-start align-items-center gap-3">
                {areFiltersActive ? (
                    <Button color="link" className="text-decoration-none text-white px-0" onClick={clearFilters}>
                        <span className="fas fa-filter-circle-xmark me-1 text-primary"/> Clear all
                    </Button>
                ) : (
                    <div>
                        <span className="fas fa-filter me-1 text-primary"/> Filters
                    </div>
                )}

                <ButtonGroup className="gap-2">
                    <Button color="primary" outline size="sm" className="rounded-3"
                            onClick={() => toggleFilterModal('actions')}>
                        {filterBy.actions.length > 0 ? (
                            <Badge color="success" className="me-2 text-white">
                                {filterBy.actions.length}
                            </Badge>
                        ) : (
                            <span className="fas fa-sort me-2"/>
                        )}
                        Actions
                    </Button>

                    <Button color="primary" outline size="sm" className="rounded-3"
                            onClick={() => toggleFilterModal('users')}>
                        {filterBy.users.length > 0 ? (
                            <Badge color="success" className="me-2 text-white">
                                {filterBy.users.length}
                            </Badge>
                        ) : (
                            <span className="fas fa-users me-2"/>
                        )}
                        Users
                    </Button>

                    <Button color="primary" outline size="sm" className="rounded-3"
                            onClick={() => toggleFilterModal('organizations')}>
                        {filterBy.organizations.length > 0 ? (
                            <Badge color="success" className="me-2 text-white">
                                {filterBy.organizations.length}
                            </Badge>
                        ) : (
                            <span className="fas fa-building me-2"/>
                        )}
                        Organizations
                    </Button>

                    <Button color="primary" outline size="sm" className="rounded-3"
                            onClick={() => toggleFilterModal('createdAt', 'dates')}>
                        {filterBy.createdAt.from || filterBy.createdAt.to ? (
                            <Badge color="success" className="me-2 text-white">
                                <span className="fas fa-calendar-check"/>
                            </Badge>
                        ) : (
                            <span className="fas fa-calendar-week me-2"/>
                        )}
                        {getFiterDatesLabel(filterBy.createdAt, 'Dates')}
                    </Button>
                </ButtonGroup>

                <div className="flex-grow-1">
                    <span className="fas fa-list me-2 text-primary"/>
                    showing {auditLogs.length} result{auditLogs.length === 1 ? '' : 's'}
                </div>

                {/*<div className="text-primary">*/}
                {/*    <Button color="link" onClick={() => setExportModal(!exportModal)}*/}
                {/*            className="text-decoration-none">*/}
                {/*        <span className="fas fa-file-excel me-1"/> Export*/}
                {/*    </Button>*/}
                {/*</div>*/}
            </div>

            {status === 'loading' && <Loading length={3}/>}

            {status === 'success' && (
                <div>
                    {filteredValues.map(({_id, action, entity, details, createdAt, organization, user}) => (
                        <div key={_id}
                             className="my-2 p-3 bg-broken border-start border-primary d-flex justify-content-between">
                            <div>
                                <p className="strong mb-0 text-primary">
                                    <span className="fas fa-clipboard-list me-2"/>
                                    [{entity.toUpperCase()}] - {action.toLowerCase()}
                                </p>
                                <p className="mb-0">{details}</p>
                            </div>

                            <div className="small">
                                <p className="mb-0 text-primary">
                                    <span className="fas fa-calendar me-2"/>
                                    {new Date(createdAt).toLocaleString()}
                                </p>
                                {organization && (
                                    <p className="mb-0">
                                        <span className="fas fa-building me-1"/> {organization.name}
                                    </p>
                                )}
                                {user && (
                                    <p className="mb-0">
                                        <span className="me-3">
                                            <span className="fas fa-user me-1"/> {user.firstName} {user.lastName}
                                        </span>
                                    </p>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            )}

            <Modal isOpen={filterModal.isOpen} toggle={() => toggleFilterModal()} size="lg" scrollable>
                <ModalHeader toggle={() => toggleFilterModal()}>
                    Filter by {filterModal.filterName || filterModal.filter}
                </ModalHeader>
                <ModalBody>
                    {filterModal.filter === 'actions' && (
                        <div className="actions-filter-modal">
                            <ListGroup>
                                {Object.keys(actions).map(action => (
                                    <ListGroupItem
                                        key={action}
                                        onClick={() => toggleFilter('actions', action)}
                                        style={{ cursor: 'pointer', borderRadius: '0' }}
                                        active={filterBy.actions.includes(action)}
                                        className="border-0 border-start border-primary border-2 position-relative p-2
                                            text-white d-flex justify-content-start gap-3 align-items-center mb-2"
                                    >
                                        <div className="flex-grow-1">
                                            {action.replace(/_/g, ' ')}
                                        </div>
                                        {filterBy.actions.includes(action) && (
                                            <div className="position-absolute top-0 end-0 mt-2 me-3 small">
                                                Selected <span className="fas fa-check-circle text-success"/>
                                            </div>
                                        )}
                                    </ListGroupItem>
                                ))}
                            </ListGroup>
                        </div>
                    )}

                    {filterModal.filter === 'createdAt' && (
                        <div className="createdAt-filter-modal">
                            <Label>Date from</Label>
                            <DatePicker
                                className="date-field w-100"
                                showIcon
                                withPortal
                                fixedHeight
                                selected={filterBy.createdAt.from}
                                onChange={handleCreatedAt('from')}
                                dateFormat="yyyy-MM-dd"
                                placeholderText="From"
                                monthsShown={2}
                                toggleCalendarOnIconClick
                                isClearable={true}
                            />
                            <Label className="mt-4">Date to</Label>
                            <DatePicker
                                className="date-field w-100"
                                showIcon
                                withPortal
                                fixedHeight
                                selected={filterBy.createdAt.to}
                                onChange={handleCreatedAt('to')}
                                dateFormat="yyyy-MM-dd"
                                placeholderText="To"
                                monthsShown={2}
                                toggleCalendarOnIconClick
                                isClearable={true}
                            />
                        </div>
                    )}

                    {filterModal.filter === 'organizations' && (
                        <div className="organizations-filter-modal">
                            <SearchBar onChange={({ target }) => setSearchModalValue(target.value)} initialValue={searchModalValue} />
                            <ListGroup>
                                {organizations.map(({ _id, name, logo }) => name.toLowerCase().includes(searchModalValue.toLowerCase()) && (
                                    <ListGroupItem
                                        onClick={() => toggleFilter('organizations', _id)}
                                        key={_id}
                                        style={{ cursor: 'pointer', borderRadius: '0' }}
                                        active={filterBy.organizations.includes(_id)}
                                        className="border-0 border-start border-primary border-2 position-relative
                                            text-white d-flex justify-content-start gap-3 align-items-center mb-2"
                                    >
                                        <img src={imgUrl(logo?.imageUrl)} width={50} alt=""/>
                                        <div className="flex-grow-1">
                                            {name}
                                        </div>
                                        {filterBy.organizations.includes(_id) && (
                                            <div className="position-absolute top-0 end-0 mt-2 me-3 small">
                                                Selected <span className="fas fa-check-circle text-success"/>
                                            </div>
                                        )}
                                    </ListGroupItem>
                                ))}
                            </ListGroup>
                        </div>
                    )}

                    {filterModal.filter === 'users' && (
                        <div className="organizations-filter-modal">
                            <SearchBar onChange={({ target }) => setSearchModalValue(target.value)} initialValue={searchModalValue} />
                            <ListGroup>
                                {users.map(user => filterTextUser(user) && (
                                    <ListGroupItem
                                        onClick={() => toggleFilter('users', user._id)}
                                        key={user._id}
                                        style={{ cursor: 'pointer', borderRadius: '0' }}
                                        active={filterBy.users.includes(user._id)}
                                        className="border-0 border-start border-primary border-2 position-relative
                                            text-white d-flex justify-content-start gap-3 align-items-center mb-2"
                                    >
                                        <img src={imgUrl(user.profilePic?.imageUrl)} width={50} alt=""/>
                                        <div className="flex-grow-1">
                                            <span className="text-primary me-3">
                                                {user.firstName} {user.lastName}
                                            </span>
                                            (<code className="text-white">{user.username}</code>)
                                            {user.parentOrganization && (
                                                <p className="small mb-0">
                                                    <span className="fas fa-building me-2"/>
                                                    {user.parentOrganization.name}
                                                </p>
                                            )}
                                        </div>
                                        {filterBy.users.includes(user._id) && (
                                            <div className="position-absolute top-0 end-0 mt-2 me-3 small">
                                                Selected <span className="fas fa-check-circle text-success"/>
                                            </div>
                                        )}
                                    </ListGroupItem>
                                ))}
                            </ListGroup>
                        </div>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => toggleFilterModal()}>
                        <span className="fas fa-check-circle me-2" />
                        Show results
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default AuditLogs;
