import React, {Fragment, useEffect, useState} from 'react';
import {NavLink, useParams} from 'react-router-dom';
import {
    Badge,
    Button,
    ButtonGroup,
    Modal,
    ModalBody,
    ModalHeader, Progress,
} from 'reactstrap';
import {useDispatch, useSelector} from 'react-redux';
import {imgUrl} from '../../../utils/api-request';
import YoutubeTrailer from '../../../components/YoutubeTrailer';
import CrewCard from '../../../components/CrewCard';
import {getLanguageByCode} from '../../../utils/languageCodes';
import '../MoviePage.scss';
import ActionButton from './ActionButton';
import {fetchOrder, getIngestJobs} from '../../../slices/orders.slice';
import {computeOrderPrice, formatCredits} from '../../../utils/credits';
import {fetchMovieVersion} from '../../../slices/movie-versions.slice';
import {getPlayingMovie, loadMovie, playMovie} from '../../../slices/play-movie.slice';
import {toast} from 'react-toastify';

const defaultCover = 'https://picsum.photos/1480/400';

const MoviePageBAC = () => {
    const { movieVersionId, orderId } = useParams();
    const dispatch = useDispatch();
    const [modalTrailer, setModalTrailer] = useState(false);
    const { version, movie, trailers, status: movieVersionStatus } = useSelector(state => state.movieVersions);
    const { currentOrder, statusIngest, ingestJobs } = useSelector(state => state.orders);
    const rolesList = ['Actor', 'Director', 'Producer', 'Writer'];
    const user = useSelector(state => state.auth.user)

    const { purchasedAt, purchasedBy, statusWorkflow, declineReason } = currentOrder || {};

    const toggleTrailer = () => {setModalTrailer(!modalTrailer)};

    const playTrailerDcp = async order => {
        try {
            await dispatch(loadMovie({ orderId, movieId: order.movie })).unwrap();
            await dispatch(playMovie({ orderId, movieId: order.movie })).unwrap();
            await dispatch(getPlayingMovie()).unwrap();
        } catch (e) {
            toast.error('Error starting playback');
        }
    };

    const isIngesting = () => {
        if (statusIngest !== 'success' || ingestJobs.length === 0) {
            return false;
        }

        const job = ingestJobs.find(({ task_parameters }) => {
            return task_parameters.uri.includes(version.uuid);
        });

        return job || false;
    };

    const calculateRemainingMinutes = (taskInfo) => {
        const startedDate = new Date(taskInfo.started_date);
        const finishedDate = new Date(taskInfo.finished_date);

        return parseInt((finishedDate - startedDate) / (1000 * 60));
    };

    useEffect(() => {
        if (orderId) {
            dispatch(fetchOrder(orderId));
        }
        dispatch(fetchMovieVersion(movieVersionId));
    }, [dispatch, movieVersionId, orderId]);

    useEffect(() => {
        let intervalId;

        if (currentOrder && currentOrder.statusWorkflow === 'ready-to-play') {
            dispatch(getIngestJobs());

            intervalId = setInterval(() => {
                dispatch(getIngestJobs());
            }, 5000);
        }

        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [currentOrder, dispatch]);

    const ingestJob = ingestJobs.find(({ task_parameters }) => {
        if (version) {
            return task_parameters.uri.includes(version.uuid);
        }

        return null;
    });

    return movieVersionStatus === 'success' && user && (
        <div className="movie-detail-view bg-black min-vh-100 pb-4">
            <NavLink to="/movies" className="text-decoration-none text-primary p-3 position-absolute z-3">
                <span className="fas fa-chevron-left me-2"/>
                Movies
            </NavLink>

            <div className="highlight-film">
                <div
                    className="film-cover d-flex flex-column align-items-end"
                    style={{backgroundImage: `url("${imgUrl(movie.still?.imageUrl) || defaultCover}")`}}
                    title="Update still"
                >
                    <div className="gradient w-100 mt-auto"></div>
                </div>

                <div className="film-actions ps-3">
                    <div className="film-logo position-relative" title="Update logo">
                        {movie.logo ? (
                            <img
                                alt={movie.title}
                                src={imgUrl(movie.logo.imageUrl)}
                                className="img-fluid"/>
                        ) : (
                            <h3 className="py-4 mt-5 text-primary text-center text-uppercase fw-bold">
                                {movie.title}
                            </h3>
                        )}
                    </div>

                    <div className="icons pb-3 mt-3">
                        <span className="release-year">{movie.releaseYear}</span>

                        <span className="restriction-genre-box ms-3">
                            {movie.genres.map(genre => (
                                <Badge key={genre} color="transparent" className="text-uppercase">{genre}</Badge>
                            ))}
                        </span>

                        <span className="language-subtitles ms-3 text-capitalize">
                            {version.assetDetails?.audioLanguage?.length > 0 && (
                                <Fragment>
                                    <span className="fas fa-language me-2"/>
                                    {(version.assetDetails && version.assetDetails?.audioLanguage.map(code => getLanguageByCode(code))) ||
                                        <span className="fas fa-minus"/>}
                                </Fragment>
                            )}

                            {version.assetDetails?.subtitlesLanguage?.length > 0 && (
                                <Fragment>
                                    <span className="fas fa-closed-captioning ms-3 me-2"/>
                                    {(version.assetDetails?.subtitlesLanguage.map(code => getLanguageByCode(code))) ||
                                        <span className="fas fa-minus"/>}
                                </Fragment>
                            )}
                        </span>

                        {orderId && (
                            <span className="price-credits ms-3">
                                <span className="fas fa-coins me-2" />
                                {computeOrderPrice(version.conditionalPrice)} credits
                            </span>
                        )}
                    </div>

                    {['site-admin', 'site-user'].includes(user.role) && (currentOrder && currentOrder.status === 'request-accepted') && (
                        <div className="border-start border-primary bg-broken my-3 p-3">
                            <p className="text-primary">Your request was accepted under the following conditions</p>
                            <strong>Screening Terms</strong>
                            <p>{currentOrder.screeningTerms}</p>

                            {currentOrder.bacCorrection !== 0 && (
                                <Fragment>
                                    <div className="row">
                                        <p className="col text-nowrap">
                                            <span className="fas fa-calendar-check me-2 text-primary"/>
                                            <strong>Screening date: </strong>{` ${new Date(currentOrder.screeningDate).toLocaleDateString()} at ${new Date(currentOrder.screeningDate).getHours()}:${new Date(currentOrder.screeningDate).getMinutes()}h`}
                                        </p>
                                        <p className="col text-nowrap">
                                            <span className="fas fa-clock me-2 text-primary"/>
                                            <strong>Hire period: </strong>{` ${currentOrder.version.defaultHirePeriod} ${currentOrder.version.hirePeriodType}`}
                                        </p>
                                    </div>
                                    <div className="row my-2 w-50">
                                        <div className="col text-nowrap">
                                            <span className="fas fa-coins me-2 text-primary "/>
                                            <strong  >Price correction:</strong>
                                        </div>
                                        <div className="col">
                                            {`${formatCredits(currentOrder?.bacCorrection?.priceCorrection)} credits`}
                                        </div>
                                    </div>
                                    <div className="row my-2 w-50">
                                        <div className='col text-nowrap' >
                                            <span className="fas fa-cash-register me-2 text-primary "/>
                                            <strong >Total:</strong>
                                        </div>
                                        <div className='col'>{`${formatCredits(Number(currentOrder?.bacCorrection?.priceCorrection) + computeOrderPrice(version.conditionalPrice, 'number')) } credits`}</div>
                                    </div>
                                </Fragment>
                            )}
                        </div>
                    )}

                    {isIngesting() ? (
                        <div className="text-white border border-primary p-4 my-3">
                            <p className="mb-1">
                                <span className="fas fa-spinner fa-spin fa-fw me-2 text-primary"/>
                                <span className="text-primary text-uppercase">
                                    Transfer in progress...{' '}
                                    ({ingestJob?.task_status?.total_progress ? `${ingestJob?.task_status?.total_progress}` : ''}%)
                                </span>
                                <br/>
                                <span className="fas fa-clock fa-fw me-2 text-primary"/>
                                <span className="text-primary me-2">ETA:</span>
                                {ingestJob?.task_infos ? calculateRemainingMinutes(ingestJob?.task_infos) : 'Unknown'} minutes left
                            </p>
                            {ingestJob?.task_status?.total_progress && (
                                <Progress
                                    value={ingestJob.task_status.total_progress}
                                    animated
                                    color="success"
                                    style={{height: '14px'}}
                                />
                            )}
                        </div>
                    ) : (
                        <ButtonGroup className="gap-3">
                            <ActionButton order={currentOrder} version={version} />
                            {trailers && trailers.length > 0 && trailers.map((trailerOrder, i) => (
                                <Button color="primary" outline onClick={() => playTrailerDcp(trailerOrder)} key={trailerOrder._id}>
                                    <span className="fas fa-video me-2"/>
                                    Play Trailer {i+1}
                                </Button>
                            ))}
                        </ButtonGroup>
                    )}

                    {statusWorkflow === 'request-declined' && declineReason && (
                        <div className="border-start border-danger mt-3 p-3 bg-broken">
                            <p className="fw-bold text-danger mb-1">Reason: </p> {declineReason}
                        </div>
                    )}

                    {statusWorkflow !== 'request-declined' && purchasedAt && (
                        <p className="active-orders small text-uppercase pt-3 mb-1 text-primary">
                            <span className="fas fa-shopping-cart me-2" />
                            Purchased at {new Date(currentOrder.purchasedAt).toLocaleString()} by {purchasedBy.firstName} {purchasedBy.lastName}
                        </p>
                    )}

                    <p className="movie-synopsis lh-2 pt-3">
                        {movie.synopsis}
                    </p>
                </div>
            </div>

            <div className="crew-cast-container p-3">
                <h5 className="text-uppercase">Crew</h5>
                <div className="crew-container d-flex flex-wrap">
                    {movie.credits.filter(({type}) => type === 'crew').map(credit => (
                        <CrewCard
                            key={credit._id}
                            type="crew"
                            credit={credit}
                            rolesList={rolesList}
                            showRole={true}
                        />
                    ))}
                </div>

                <h5 className="text-uppercase mt-3">Cast</h5>
                <div className="cast-container d-flex flex-wrap">
                    {movie.credits.filter(({type}) => type === 'cast').map(credit => (
                        <CrewCard
                            key={credit._id}
                            type="cast"
                            credit={credit}
                            rolesList={rolesList}
                            showRole={false}
                        />
                    ))}
                </div>
            </div>

            <div className="clearfix"/>

            <Modal isOpen={modalTrailer} toggle={toggleTrailer} fullscreen modalClassName="trailer">
                <ModalHeader tag="div" toggle={toggleTrailer} className="non-editable" />
                <ModalBody>
                    <YoutubeTrailer
                        videoId={movie.youtubeTrailerId}
                        title={movie.title}
                        value={movie.youtubeTrailerId}
                    />
                </ModalBody>
            </Modal>
        </div>
    )
}

export default MoviePageBAC;
